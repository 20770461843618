import * as React from 'react';

import { Container, Title, Content } from './styled';

import { toClassNameObj } from '../../services/helpers';

type Props = {
  children: React.ReactNode;
  title: string;
  className?: string;
  shortTitle?: boolean;
};

export default function ContentBox({ children, title, className = '', shortTitle = false }: Props) {
  return (
    <Container shortTitle={shortTitle} {...toClassNameObj(className)}>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  );
}
