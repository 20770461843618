import * as React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './components/App';

const { REACT_APP_SENTRY_DSN } = process.env;

if (REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
  });
}

(window as any).APP_TITLE = document.title;

const container = document.createElement('div');
document.body.appendChild(container);

ReactDOM.render(<App />, container);
