import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ArrowIcon } from './icons';

type buttonProps = {
  arrow: boolean;
  background: string;
};
export const ButtonStyled = styled.button<buttonProps>`
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors[props.background]};
  border: solid 1px ${props => props.theme.colors.black};
  box-shadow: 1px 2px 0 0 ${props => props.theme.colors.black};
  display: flex;
  font-family: ${props => props.theme.fonts.backIssuesBB};
  font-style: italic;
  font-weight: 700;
  height: 40px;
  padding: ${props => (props.arrow ? ' 0 10px 0 15px' : '0 20px')};
  text-transform: uppercase;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 2px 3px 0 0 ${props => props.theme.colors.black};
  }
`;

export const LinkStyled = ButtonStyled.withComponent(({ arrow, background, ...rest }) => (
  <Link {...rest} />
));

export const ArrowIconStyled = styled(ArrowIcon)`
  margin-left: 10px;
`;
