import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

type buttonProps = {
  arrow: boolean;
  background: string;
};
// export const ButtonStyled = styled.button<buttonProps>`
//   align-items: center;
//   background: ${props => props.theme.colors[props.background]};
//   display: flex;
//   font-family: ${props => props.theme.fonts.backIssuesBB};
//   font-style: italic;
//   font-weight: 700;
//   height: 40px;
//   padding: ${props => (props.arrow ? ' 0 10px 0 15px' : '0 20px')};
//   text-transform: uppercase;
//   transition: box-shadow 0.3s ease-in-out;
//
//   &:hover {
//     box-shadow: 2px 3px 0 0 ${props => props.theme.colors.black};
//   }
// `;

export const ButtonStyled = styled.button<buttonProps>``;

export const LinkStyled = ButtonStyled.withComponent(({ arrow, background, ...rest }) => (
  <Link {...rest} />
));

