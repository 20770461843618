import * as React from 'react';

import { toClassNameObj } from '../../services/helpers';

type iconProps = {
  className?: string;
};

export function ArrowIcon({ className = '' }: iconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      {...toClassNameObj(className)}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12.71.297a.997.997 0 0 0-1.418-.002l.016-.016a1.002 1.002 0 0 0 .001 1.417l4.182 4.182c.391.391.259.709-.292.709H1a.998.998 0 0 0-1.001 1c0 .552.45 1 1.001 1H15.2c.553 0 .684.317.292.71l-4.182 4.18a.997.997 0 0 0 0 1.418l-.017-.016a1.008 1.008 0 0 0 1.418-.002l6.58-6.58a1.01 1.01 0 0 0 0-1.42L12.71.297z"
      />
    </svg>
  );
}
