import * as React from "react";
import { Link } from "react-router-dom";
import { userType } from "../../../contexts/AuthProvider";
import { Container, Username, LinkStyled } from "./styled";

type Props = {
  active: boolean;
  user: userType;
};

export default function UserProfile({ active, user }: Props) {
  if (user === null) return null;

  return (
    <Container>
      <Username>
        <LinkStyled active={active} to="/account">
          {user.fullName}
        </LinkStyled>
      </Username>
    </Container>
  );
}
