import ContentBox from '../../components/ContentBox';
import ButtonCustom from '../../components/Button';
import { ClaimDetailsRow } from './ClaimDetailsRow';

const tableStyle = { width: '100%', borderSpacing: 0, border: '1px solid black', marginBottom: 10 };


export const ClaimDetails = ({ claims, handleBack, gotoSubmit }) => {
    return (
        <ContentBox title={'Claim Details'}>
            <div>
                <ButtonCustom onClick={handleBack} arrow={false}>
                    Back
                </ButtonCustom>
            </div>

            {!claims || claims.length === 0 ? (
                <p>No claim found for this video, please submit a claim request.</p>
            ) : (
                <>
                    <p>SuperBam has claimed this video {claims.length} times. Request a claim if you need more information.</p>

                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th>Target Video</th>
                                <th>Original Video</th>
                            </tr>
                        </thead>
                        <tbody>
                            {claims.map((claim, k) => (
                                <ClaimDetailsRow key={k} index={k} claim={claim}/>
                            ))}
                        </tbody>
                    </table>
                </>
            )}

            <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                <ButtonCustom onClick={gotoSubmit} arrow={false}>
                    Request a claim
                </ButtonCustom>
            </div>
        </ContentBox>
    );
};
