

export const ClaimCheckInstructions = () => {
    return (
        <>
        <div style={{ alignItems: 'center' }}>

            <p>Make sure your video link is in one of the following accepted URL formats:</p>
            <p><ol>
                <li><code>https://youtube.com/shorts/x1xx1111x1XX?feature=share</code></li>
                <li><code>https://www.youtube.com/watch?v=x1xx1111x1XX</code></li>
                <li><code>https://www.youtube.com/watch?v=x1xx1111x1XX?feature=share</code></li>
                <li><code>https://youtu.be/x1xx1111x1XX</code></li>
                <li><code>https://www.youtube.com/shorts/x1xx1111x1XX</code></li>
                <li><code>https://youtube.com/shorts/x1xx1111x1XX?feature=share</code></li>
                <li><code>https://www.youtube.com/watch?v=x1xx1111x1XX&list=XXxXxxXxXXxxxX1xXx-1-XXx</code></li>
                <li><code>https://www.youtube.com/watch?v=x1xx1111x1XX&list=XXxXxxXxXXxxxX1xXx-1-XXx&index=3</code></li>
            </ol></p>
        </div>
        </>
    );
};
