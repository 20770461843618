import { createGlobalStyle } from 'styled-components';

import BackIssuesBbBoldItalic from './fonts/BackIssuesBbBoldItalic.woff';
import BackIssuesBbBoldItalic2 from './fonts/BackIssuesBbBoldItalic.woff2';

import BackIssuesBbIRegular from './fonts/BackIssuesBbIRegular.woff';
import BackIssuesBbIRegular2 from './fonts/BackIssuesBbIRegular.woff2';

import BackIssuesBbItalic from './fonts/BackIssuesBbItalic.woff';
import BackIssuesBbItalic2 from './fonts/BackIssuesBbItalic.woff2';

import BackgroundImage from './images/Background.jpg';

import { themeType } from '../../theme';

export const GlobalStyle = createGlobalStyle<{ theme: themeType }>`
  *, *::before, *::after {
    box-sizing: border-box;
    outline: 0;
  }
  
  html {
    font-size: 100%;
    height: 100%;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    background: ${props =>
      props.theme.colors.whiteTwo} url(${BackgroundImage}) no-repeat center center fixed;
    background-size: cover;
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.fonts.roboto};
    font-size: 1rem;
  }
  
  a, button {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  input, button, textarea {
    font: inherit;
  }
  
  textarea {
    resize: none;
  }
`;

export const FontStyles = createGlobalStyle<{ theme: themeType }>`
  @font-face {
    font-family: ${props => props.theme.fonts.backIssuesBB};
    src: url(${BackIssuesBbBoldItalic2}) format('woff2'), url(${BackIssuesBbBoldItalic}) format('woff');
    font-weight: 700;
    font-style: italic;
  }
    
  @font-face {
    font-family: ${props => props.theme.fonts.backIssuesBB};
    src: url(${BackIssuesBbIRegular2}) format('woff2'), url(${BackIssuesBbIRegular}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
    
  @font-face {
    font-family: ${props => props.theme.fonts.backIssuesBB};
    src: url(${BackIssuesBbItalic2}) format('woff2'), url(${BackIssuesBbItalic}) format('woff');
    font-weight: normal;
    font-style: italic;
  }
`;
