import * as React from 'react';

import {toSrcSet} from '../../services/helpers';

import {
    ButtonStyled,
    ContainerGrid,
    LoginBox,
    LogoImage,
    GridItem,
    InputLabel,
} from './styled';

import LogoPicture1 from './images/Logo.png';
import LogoPicture2 from './images/Logo@2x.png';
import LogoPicture3 from './images/Logo@3x.png';
import {HomeButton} from "../Account/styled";
import history from '../../@history';
import {useEffect} from "react";

type Props = {
    onResetPassword: (password: string, passwordToken: string) => void;
    emailSentMessage: string;
};

const data = {
    password: '',
    confirmPassword: '',
    passwordError: '',
    confirmPasswordError: '',
    enableButton: false,
    passwordToken: '',
};

export default function ResetPassword({onResetPassword, emailSentMessage}: Props) {

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            if (event.target.value.length > 4) {
                data.password = event.target.value
            }
        };

    const handleConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            if (event.target.value.length > 4) {
                data.confirmPassword = event.target.value
            }
            data.enableButton = !!(data.password && data.password === data.confirmPassword);
        };

    const handleResetPassword = () => {
        if (data.password === data.confirmPassword)
            onResetPassword(data.password, data.passwordToken)
    };

    const token = history.location.pathname ? history.location.pathname.split('/').pop() : '';
    data.passwordToken = token ? token : '';

    return (
        <ContainerGrid>
            <GridItem>
                <LoginBox>
                    <LogoImage src={LogoPicture1} alt="SuperBam" {...toSrcSet(LogoPicture2, LogoPicture3)} />
                    {emailSentMessage.length ? <><p>{emailSentMessage}</p>
                        <a href="/">Go to Login</a>
                    </> : <>
                        <InputLabel type="password" placeholder="Password" required onChange={handlePasswordChange}/>
                        <InputLabel type="password" placeholder="Confirm Password" required
                                    onChange={handleConfirmPasswordChange}/>
                        <ButtonStyled background="blue" onClick={handleResetPassword}>
                            Update Password
                        </ButtonStyled></>}

                </LoginBox>
            </GridItem>
        </ContainerGrid>
    );
}
