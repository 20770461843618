import styled from 'styled-components';

export const Disclaimer = styled.p`
  font-family: ${props => props.theme.fonts.backIssuesBB};
`

export const Section = styled.section`
  padding: 120px 0;

  ${props => props.theme.mui.breakpoints.up('sm')} {
    margin: 0 1rem;
  }

  ${props => props.theme.mui.breakpoints.up('md')} {
    margin: 0 auto;
    max-width: ${props => props.theme.container};
  }
`;


export const Anchor = styled.a`
  text-decoration: underline !important;
  font-style: italic !important;
`;
