import * as React from 'react';
import { ApolloProvider } from '@apollo/client/react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Theme, withTheme } from '@material-ui/core/styles';

import AuthProvider from './AuthProvider';

import { getApolloClient } from '../services/apollo';

import theme from '../theme';

type Props = {
  children: React.ReactNode;
  theme: Theme;
};

function ContextProvider({ children, theme: muiTheme }: Props) {
  return (
    <ThemeProvider theme={{ ...theme, mui: muiTheme }}>
      <ApolloProvider client={getApolloClient()}>
        <BrowserRouter>
          <AuthProvider>{children}</AuthProvider>
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default withTheme()(ContextProvider);
