import { Card, FormControl, TextField } from '@material-ui/core';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import ContentBox from '../../components/ContentBox';
import { ComponentLoaderStyled, Error } from './styled';

const GET_CHECK_CLAIM = gql`
    query getCheckClaim($video_id: String!) {
        claims(video_id: $video_id) @rest(type: "Claims", path: "/check_claim/youtube?{args}") {
            targetVideo {
                date
                videoId
                channelId
            }
            originalVideo {
                assetId
                season
                episodeTitle
                customId
                channel {
                    channelName
                }
            }
        }
    }
`;

export const ClaimCheck = ({ handleResp, newMessage }) => {
    const [videoId, setVideoId] = useState('');
    const [disable_button, setDisableButton] = useState(false);
    const onCompleted = (resp) => {
        handleResp(resp.claims, videoId);
    };
    const onError = (error) => {
        console.log(error);
        handleResp(null, videoId);
    }

    const [getClaimCheck, { loading, error }] = useLazyQuery(GET_CHECK_CLAIM, { onCompleted, onError });

    const onClickCheck = () => {
        getClaimCheck({ variables: { video_id: videoId } });
    };

    function youtubeParser(url){
        var regExp = /^https?:\/\/(?:www\.youtube(?:ocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|shorts\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#&?\n/<>"']*)/i;
        var match = url.match(regExp);
        return (match && match[1].length===11)? match[1] : false;
    }


    const handleVideoIdChange = (value) => {
        const youtube_parser = youtubeParser(value)
        setVideoId(value)
        newMessage(!youtube_parser);
        setDisableButton(youtube_parser);
    }

    return (
        <ContentBox title={'Claim Checker'}>
            {loading ? (
                <ComponentLoaderStyled />
            ) : (
                <form>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField value={videoId} placeholder={"Enter YouTube video link"}
                               onChange={(e) => handleVideoIdChange(e.target.value)}
                               variant="outlined" style={{ marginRight: 20, flex: 1 }} />
                    {disable_button ? <Button type={'submit'} onClick={onClickCheck}>Check Claim</Button>: false}
                </div>
                </form>
            )}
            
            {error ? (
                <Error>
                    {error.name}: {error.message}
                </Error>
            ) : null}
        </ContentBox>
    );
};
