import * as React from "react";
import { Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import ComponentLoader from "../ComponentLoader";

import { AuthContext } from "../../contexts/AuthProvider";
import ContextProvider from "../../contexts/ContextProvider";
import { GlobalStyle, FontStyles } from "./styled";
import ForgotPassword from "../../scenes/ForgotPassword";
import ResetPassword from "../../scenes/ResetPassword";
import UpdatePassword from "../../scenes/UpdatePassword";
import { ClaimChecker } from "../../scenes/ClaimChecker";
import NewForgotPassword from "../../scenes/NewForgotPassword";
import NewResetPassword from "../../scenes/NewResetPassword";

const Account = React.lazy(() => import("../../scenes/Account"));
const Login = React.lazy(() => import("../../scenes/Login"));
const Overview = React.lazy(() => import("../../scenes/Overview"));
const Channels = React.lazy(() => import("../../scenes/Channels"));
// const OverviewCreator = React.lazy(() => import('../../scenes/OverviewCreator'));
const TeamOverView = React.lazy(() => import("../../scenes/Team"));
const Reports = React.lazy(() => import("../../scenes/Reports"));
const SignUp = React.lazy(() => import("../../scenes/SignUp"));
const Contact = React.lazy(() => import("../../scenes/Contact"));

export default function App() {
  return (
    <ContextProvider>
      <FontStyles />
      <CssBaseline />
      <GlobalStyle />
      <React.Suspense fallback={<ComponentLoader />}>
        <AuthContext.Consumer>
          {(auth) =>
            auth.isAuthenticated() ? (
              auth.loaded ? (
                auth.hasAccepted() ? (
                  auth.user?.resetPassword === true ? (
                    <UpdatePassword />
                  ) : (
                    <Switch>
                      {/*<Route path="/" component={() => <Overview user={auth.user} selectedUser={auth.selectedUser} onCompanySelected={auth.onCompanySelected} selectedChannel={auth.selectedChannel}/>} exact />*/}
                      <Route
                        path="/"
                        component={() => (
                          <Channels
                            user={auth.user}
                            onCompanySelected={auth.onCompanySelected}
                            selectedChannel={auth.selectedChannel}
                          />
                        )}
                        exact
                      />
                      {/*<Route path="/overview" component={() => <OverviewCreator user={auth.user} onCompanySelected={auth.onCompanySelected} selectedChannel={auth.selectedChannel}/>} exact />*/}
                      <Route
                        path="/overview"
                        component={() => (
                          <Overview
                            user={auth.user}
                            selectedUser={auth.selectedUser}
                            onCompanySelected={auth.onCompanySelected}
                            setInterval={auth.setInterval}
                            interval={
                              auth.user && auth.user.interval
                                ? auth.user.interval
                                : "30"
                            }
                            selectedChannel={auth.selectedChannel}
                          />
                        )}
                        exact
                      />
                      <Route
                        path="/claim-checker"
                        component={() => (
                          <ClaimChecker
                            user={auth.user}
                            onCompanySelected={auth.onCompanySelected}
                          />
                        )}
                        exact
                      />
                      <Route
                        path="/team"
                        component={() => (
                          <TeamOverView
                            user={auth.user}
                            selectedUser={auth.selectedUser}
                            onCompanySelected={auth.onCompanySelected}
                            selectedChannel={auth.selectedChannel}
                          />
                        )}
                      />
                      <Route
                        path="/reports"
                        component={() => (
                          <Reports
                            user={auth.user}
                            onCompanySelected={auth.onCompanySelected}
                            selectedChannel={auth.selectedChannel}
                            getSelectedChannel={auth.getSelectedChannel}
                          />
                        )}
                      />
                      <Route
                        path="/account"
                        component={() => (
                          <Account
                            user={auth.user}
                            onCompanySelected={auth.onCompanySelected}
                            selectedChannel={auth.selectedChannel}
                          />
                        )}
                      />
                      <Route
                        path="/reset-password"
                        component={() => (
                          <NewResetPassword
                            onAuthResetPassword={auth.onAuthResetPassword}
                            passwordResetMessage={auth.passwordResetMessage}
                          />
                        )}
                      />
                      <Route
                        path="/contact"
                        component={() => (
                          <Contact
                            user={auth.user}
                            onCompanySelected={auth.onCompanySelected}
                          />
                        )}
                      />
                    </Switch>
                  )
                ) : (
                  <SignUp />
                )
              ) : (
                <ComponentLoader />
              )
            ) : (
              <>
                <Route
                  path="/forgot"
                  component={() => (
                    <ForgotPassword
                      onForgotPassword={auth.onForgotPassword}
                      emailSentMessage={auth.emailSentMessage}
                    />
                  )}
                />
                <Route
                  path="/reset_password/:token"
                  component={() => (
                    <ResetPassword
                      onResetPassword={auth.onResetPassword}
                      emailSentMessage={auth.emailSentMessage}
                    />
                  )}
                />
                {!auth.passwordReset ? (
                  <React.Suspense fallback={<ComponentLoader />}>
                    <Login
                      onLogin={auth.login}
                      loginFailMessage={auth.loginFailMessage}
                    />
                  </React.Suspense>
                ) : (
                  false
                )}
              </>
            )
          }
        </AuthContext.Consumer>
      </React.Suspense>
    </ContextProvider>
  );
}
