import * as React from 'react';

import { ButtonStyled, LinkStyled } from './styled';

import { toClassNameObj } from '../../services/helpers';
import { StyledComponent } from 'styled-components';

type buttonProps = {
  children: React.ReactNode;
  arrow?: boolean;
  background?: string;
  className?: string;
  onClick?: () => void;
  to?: string;
  type?: string;
};

type componentProps = {
  arrow: boolean;
  background: string;
  onClick: () => void;
  to?: string;
  type?: string;
};

export default function ButtonTransparent({
  children,
  arrow = true,
  background = 'transparent',
  className = '',
  onClick = () => {},
  to = '',
  type = 'button',
}: buttonProps) {
  const props: componentProps = { arrow, background, onClick };
  const Component: StyledComponent<any, any> = to ? LinkStyled : LinkStyled;

  if (to) {
    props.to = to;
  } else {
    props.type = type;
  }

  return (
    <Component {...props} {...toClassNameObj(className)}>
      {children}
    </Component>
  );
}
