import * as React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import Button from '../../components/Button';

import { GoogleIcon } from './icons';

export const ButtonStyled = styled(Button)`
  margin: 0 auto;
`;

export const ContainerGrid = styled(Grid as React.FunctionComponent).attrs({
  container: true,
  justify: 'center',
})`
  height: 100vh;
  margin: 0 auto;
  max-width: ${props => props.theme.container};
`;

export const GridItem = styled(Grid as React.FunctionComponent).attrs({ item: true, sm: 6 })`
  align-items: center;
  display: flex;
`;

export const LoginBox = styled.div`
  background: ${props => props.theme.colors.whiteTwo};
  border: solid 1px ${props => props.theme.colors.black};
  box-shadow: 1px 2px 0 0 ${props => props.theme.colors.black};
  margin: auto;
  padding: 40px 20px 80px 20px;
  text-align: center;
`;

export const LogoImage = styled.img`
  display: block;
  margin: 0 auto 40px auto;
`;

export const GiphyImage = styled.img`
  display: block;
  width: 100%;
`;

export const GiphyContainer = styled.div`
  margin-bottom: 80px;
  position: relative;
`;

export const GiphyLabel = styled.p`
  -webkit-text-stroke: 1px ${props => props.theme.colors.black};
  bottom: 20px;
  color: ${props => props.theme.colors.sunYellow};
  font-family: ${props => props.theme.fonts.backIssuesBB};
  font-size: 0.9375em;
  font-style: italic;
  font-weight: 700;
  left: 0;
  line-height: 1.33em;
  margin: 0;
  padding: 0 20px;
  position: absolute;
  text-align: center;
  text-shadow: 1px 1px 0 ${props => props.theme.colors.cherryRed};
  text-transform: uppercase;
  width: 100%;
`;

export const InputLabel = styled.input`
    height: 40px;
    width: 100%;
    padding: 8px;
    margin-bottom: 4px;
`;

export const GoogleIconStyled = styled(GoogleIcon)`
  margin-right: 15px;
`;
