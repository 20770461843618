import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { Fragment, useState } from 'react';
import ButtonCustom from '../../components/Button';
import ContentBox from '../../components/ContentBox';
import { gql, useMutation } from '@apollo/client';
import {
    ComponentLoaderStyled,
    Error,
    ExampleFlex,
    ComponentOne,
    ComponentTwo,
    ComponentThree
} from './styled';

const POLICIES = [
    { label: 'Claim and Monetize', value: 'claim' },
    { label: 'Block', value: 'block' },
    { label: 'Takedown', value: 'takedown' },
    { label: 'Release a Claim', value: 'release' },
];

const REQ_CLAIM = gql`
    mutation RequestClaim($channel_id: String!, $original_video: String!, $target_video: String!, $policy: String!, $notes: String!, $infraction_timestamp: [String]!) {
        requestClaim(input: { channel_id: $channel_id, original_video: $original_video, target_video: $target_video, policy: $policy, notes: $notes, infraction_timestamp: $infraction_timestamp })
            @rest(path: "/request_claim", method: "POST") {
            resp
        }
    }
`;

const MINUTES: any = [];
for (let i = 0; i <= 240; i++) {
    MINUTES.push({ label: i.toString(), value: i.toString() });
}

const SECONDS: any = [];
for (let i = 0; i <= 60; i++) {
    SECONDS.push({ label: i.toString(), value: i.toString() });
}

export const ClaimSubmit = ({ videoId, selected_channel, handleBack }) => {
    const [targetUrl, setTargetUrl] = useState(videoId);
    const [selectedChannel, setSelectedChannel] = useState(selected_channel?.channel_id);
    const [originalUrl, setOriginalUrl] = useState('');
    const [policy, setPolicy] = useState('claim');
    const [note, setNote] = useState('');
    const [timestamps, setTimestamps] = useState([] as string[]);
    const [message, setMessage] = useState('');
    const [requestClaim, { loading, data, error }] = useMutation(REQ_CLAIM);
    const [inputList, setInputList] = useState([{ minutes: "", seconds: "" }]);

    // const onChangeTimestamps = (chips: string[]) => {
    //     setTimestamps(chips);
    // };

    const onSubmit = async () => {
        let list: string[] = []
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i].minutes && inputList[i].seconds) {
                list.push(inputList[i].minutes + "m" + inputList[i].seconds + "s")
            } else if (inputList[i].minutes || inputList[i].seconds) {
                if (inputList[i].minutes.length && !inputList[i].seconds.length) {
                    list.push(inputList[i].minutes + "m0s")
                } else if (!inputList[i].minutes.length && inputList[i].seconds.length) {
                    list.push("0m" + inputList[i].seconds + "s")
                }
            }
        }
        if (targetUrl && policy && list.length > 0 && originalUrl) {
            setMessage('')
            try {
                await requestClaim({
                    variables: {
                        channel_id: selectedChannel,
                        target_video: targetUrl,
                        policy: policy,
                        notes: note,
                        infraction_timestamp: list,
                        original_video: originalUrl,
                    },
                });
            } catch (err) {
                console.error(err);
            }
        } else {
            setMessage('To Submit a Claim Request, please fill out all form fields unless noted otherwise.')
        }
    };
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { minutes: "", seconds: "" }]);
    };

    return (
        <ContentBox title={'Submit Claim Request'}>
            <div style={{ display: 'flex', flexFlow: 'column nowrap', rowGap: 24 }}>
                <div>
                    <ButtonCustom onClick={handleBack} arrow={false}>
                        Back
                    </ButtonCustom>
                </div>
                {loading ? (
                    <ComponentLoaderStyled />
                ) : error ? (
                    <Error>
                        {error.name}: {error.message}
                    </Error>
                ) : data ? (
                    <pre>Successfully submitted.</pre>
                ) : (
                    <Fragment>
                        <TextField value={targetUrl} onChange={(e) => setTargetUrl(e.target.value)}
                            label="Target Video URL" />
                        <TextField value={originalUrl} onChange={(e) => setOriginalUrl(e.target.value)}
                            label="Your Original Video URL" />

                        <FormControl>
                            <InputLabel>What action do you want to take?</InputLabel>

                            <Select value={policy} onChange={(e) => setPolicy(e.target.value)}>
                                {POLICIES.map((p, k) => (
                                    <MenuItem key={k} value={p.value}>
                                        {p.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/*<ChipInput onChange={(chips) => onChangeTimestamps(chips)} label="When does your content appear in this video? (e.g. 1m21s, 1m35s)" />*/}

                        {/*<TextField multiline value={timestamps} onChange={(e) => setTimestamps(e.target.value)} label="When does your content appear in this video? (e.g. 1m21s, 1m35s)" />*/}
                        <InputLabel>When does your content appear in this video? (e.g. 1m21s, 1m35s)</InputLabel>
                        {inputList.map((x, i) => {
                            return (
                                <ExampleFlex>
                                    <ComponentOne>
                                        <label style={{ marginRight: "10px" }}>Minute(s): </label>
                                        <Select name="minutes" value={x.minutes} style={{ width: "100px" }}
                                            onChange={e => handleInputChange(e, i)}>
                                            {MINUTES.map((p, k) => (
                                                <MenuItem key={k} value={p.value} selected={p.value === x.minutes}>
                                                    {p.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </ComponentOne>
                                    <ComponentTwo>
                                        <label style={{ marginRight: "10px" }}>Second(s): </label>
                                        <Select name="seconds" value={x.seconds} style={{ width: "100px" }}
                                            onChange={e => handleInputChange(e, i)}>
                                            {SECONDS.map((p, k) => (
                                                <MenuItem key={k} value={p.value} selected={p.value === x.seconds}>
                                                    {p.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </ComponentTwo>
                                    <ComponentThree>
                                        {inputList.length !== 1 && <ButtonCustom
                                            onClick={() => handleRemoveClick(i)}>Remove</ButtonCustom>}
                                        {inputList.length - 1 === i && <ButtonCustom onClick={handleAddClick}>Add</ButtonCustom>}
                                    </ComponentThree>
                                </ExampleFlex>
                            );
                        })}
                        <TextField multiline value={note} onChange={(e) => setNote(e.target.value)}
                            label="Anything else we should know? (optional)" />
                        {/*<FormControlLabel control={<Checkbox checked={multiInf} onChange={(e) => setMultiInf(e.target.checked)} />} label="Are there multiple infractions?" />*/}
                        {message.length > 0 ? (
                            <div style={{ width: '100%', color: 'red' }}>
                                <p style={{ margin: '0 auto' }}>{message}</p>
                            </div>
                        ) : false}
                        <ButtonCustom arrow={false} onClick={() => onSubmit()}>
                            submit
                        </ButtonCustom>
                    </Fragment>
                )}
            </div>
        </ContentBox>
    );
};
