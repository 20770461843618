import * as React from "react";
import { Link } from "react-router-dom";
import { Container, Username, LinkStyled } from "../UserProfile/styled";
import styled from "styled-components";

type Props = {
  active: boolean;
};

export const ContactLinkStyled = styled(LinkStyled)`
  margin-right: 50px;
`;

export default function Contact({ active }: Props) {
  return (
    <Container>
      <Username>
        <ContactLinkStyled active={active} to="/contact">
          Need help?
        </ContactLinkStyled>
      </Username>
    </Container>
  );
}
