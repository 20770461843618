import styled, { css } from 'styled-components';
import ComponentLoader from '../../components/ComponentLoader';

export const ComponentLoaderStyled = styled(ComponentLoader)`
  position: absolute;
`;

export const Error = styled.p`
  margin: 0;
  padding-top: 50px;
  text-align: center;
`;

// flex row component
export const ExampleFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

// component one
export const ComponentOne = styled(ExampleFlex)`
  width: 33%;
  justify-content: flex-start;
  align-items: center;
`

// component two
export const ComponentTwo = styled(ExampleFlex)`
  width: 33%;
  justify-content: flex-start;
  align-items: center;
`

// component three
export const ComponentThree = styled(ExampleFlex)`
  width: 33%;
  justify-content: flex-end;
  align-items: center;
`
