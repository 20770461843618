import * as React from 'react';

import { Container, Loader } from './styled';

import { toClassNameObj } from '../../services/helpers';

type Props = {
  className?: string;
};

export default function ComponentLoader({ className = '' }: Props) {
  return (
    <Container {...toClassNameObj(className)}>
      <Loader />
    </Container>
  );
}
