import { Helmet } from 'react-helmet';
import { userType } from '../../contexts/AuthProvider';

import {
  Username,
  SinceDate,
  TitleLine,
  ReportsButton,
  Title,
  Container,
  LogoutButton,
  ResetPasswordButton
} from './styled';

type Props = {
  title: string;
  user: userType;
  logoutButton?: boolean;
  logoutCallback?: () => void;
};

export default function UserInfo({
  user,
  title,
  logoutButton = false,
  logoutCallback = () => {},
}: Props) {
  if (user === null) return null;
  const channelObj:any = user?.channels?.filter((item: any) => item?.channel_id === user?.selected_channel)[0];
  return (
    <>
      <Helmet>
        <title>
          {title} - {(window as any).APP_TITLE || ''}
        </title>
      </Helmet>
        <Title>
            {window.location.pathname === '/claim-checker' ||
              window.location.pathname === '/reports' ||  window.location.pathname === '/overview' ?
                (<>
                    {window.location.pathname === '/reports' ? (<><ReportsButton to="/overview">Overview</ReportsButton>
                            <TitleLine>{title}</TitleLine>
                      <ReportsButton to="/claim-checker">Claim Checker</ReportsButton>
                        </>) : <>{window.location.pathname === '/overview' ? <><TitleLine>{title}</TitleLine>
                      <ReportsButton to="/reports">Reports</ReportsButton>
                      <ReportsButton to="/claim-checker">Claim Checker</ReportsButton>
                    </> : <>{window.location.pathname === '/claim-checker' ? <>
                      <ReportsButton to="/overview">Overview</ReportsButton>
                      <ReportsButton to="/reports">Reports</ReportsButton>
                      <TitleLine>{title}</TitleLine></> : false}</>}</>}</>) :
                <TitleLine>{title}</TitleLine>}
        </Title>
      <Container>
        <div>
            {window.location.pathname === '/' && user && user.selected_company ? <Username>{user.companies.find((item: any) => item.company_id === user.selected_company).company_name}</Username>:
          <Username>
              {window.location.pathname === '/reports' ||  window.location.pathname === '/overview'
              ||  window.location.pathname === '/claim-checker' ?
                  (<>
              {channelObj ? channelObj.channel_name : false}</>) : user.fullName}</Username>}
          {/* TODO - get actual sign-up date instead of updatedAt */}
          {/*<SinceDate>using SuperBam since {formatDate(user.createdAt)}</SinceDate>*/}
        </div>
        {logoutButton && <LogoutButton onClick={logoutCallback}>Log out</LogoutButton>}
        {logoutButton && <ResetPasswordButton to={'/reset-password'}>Reset Password</ResetPasswordButton>}
      </Container>
    </>
  );
}
