export function toSrcSet(...pictures: any[]): { srcSet?: string } {
  if (Array.isArray(pictures[0])) pictures = pictures[0];
  const srcSet = pictures.map((picture, index) => `${picture} ${index + 2}x`).join(', ');
  return srcSet ? { srcSet } : {};
}

export function toImageSet(...pictures: any[]): string {
  if (Array.isArray(pictures[0])) pictures = pictures[0];
  return pictures.map((picture, index) => `${picture} ${index + 1}x`).join(', ');
}

export function toClassNames(...classNames: any[]): string {
  if (Array.isArray(classNames[0])) classNames = classNames[0];
  return classNames.filter(name => !!name).join(' ');
}

export function toClassNameObj(...classNames: any[]): { className?: string } {
  const className = toClassNames(classNames);
  return className ? { className } : {};
}

type toNumberOptions = {
  currency?: string | boolean;
  shorthand?: boolean;
  decimals?: boolean | null;
};
export function toNumber(value: number, userOptions: toNumberOptions = {}): string {
  const defaultOptions = {
    currency: '$',
    shorthand: false,
    decimals: null,
  };
  const options: toNumberOptions = { ...defaultOptions, ...userOptions };

  function shorten(number: number, k: string[] = []): string {
    return number >= 1000
      ? shorten(Math.round(number / 1000), [...k, 'K'])
      : `${number}${k.join('')}`;
  }

  let out: string = '';

  if (options.shorthand) {
    out = shorten(value);
  } else {
    out = value
      .toFixed(2)
      .toString()
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    if (options.decimals === false || (options.decimals === null && value % 1 <= 0)) {
      out = out.replace(/\.\d{2}$/g, '');
    }
  }

  if (options.currency) out = `${options.currency}${out}`;

  return out;
}

type formatDateOptions = {
  hideDay?: boolean;
  hideYear?: boolean;
};
export function formatDate(dateStr: string, userOptions: formatDateOptions = {}): string {
  const defaultOptions = {
    hideDay: false,
    hideYear: false,
  };
  const options: formatDateOptions = { ...defaultOptions, ...userOptions };
  // TODO - might use some date lib in future
  const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(dateStr);

  const out: string[] = [MONTHS[date.getMonth()]];
  if (!options.hideDay) out.push(`${date.getDate()}${options.hideYear ? '' : ','}`);
  if (!options.hideYear) out.push(date.getFullYear().toString());

  return out.join(' ');
}

const longDateFormatOptions: Intl.DateTimeFormatOptions = { timeZone: 'UTC', month: 'long', year: 'numeric' };
const shortDateFormatOptions: Intl.DateTimeFormatOptions = { timeZone: 'UTC', month: 'short', year: 'numeric' };

export function formatIsoDateMonthYear(isoDate: string) {
  const dateFormatter = new Intl.DateTimeFormat('en-US', longDateFormatOptions);
  return dateFormatter.format(new Date(isoDate));
}

export function formatIsoDateShortMonthYear(isoDate: string) {
  const dateFormatter = new Intl.DateTimeFormat('en-US', shortDateFormatOptions);
  return dateFormatter.format(new Date(isoDate));
}
