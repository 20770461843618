import * as React from "react";
import Navigation from "./Navigation";
import UserProfile from "./UserProfile";
import Contact from "./Contact/index";
import { userType } from "../../contexts/AuthProvider";

import {
  Container,
  GridStyled,
  Sidebar,
  LinkStyled,
  DivStyled,
} from "./styled";

import { toSrcSet } from "../../services/helpers";
import LogoImage1 from "./images/NewLogo.png";
import LogoImage2 from "./images/NewLogo@2x.png";
import LogoImage3 from "./images/NewLogo@3x.png";
import { Username } from "./UserProfile/styled";
import "./style.css";

const MANAGER_NAVIGATION = [
  // {
  //   title: 'Overview',
  //   to: '/',
  // },
  // {
  //   title: 'Reports',
  //   to: '/reports',
  // },
  {
    title: "Team",
    to: "/team",
  },
];

const NAVIGATION = [
  {
    title: "Overview",
    to: "/",
  },
  {
    title: "Reports",
    to: "/reports",
  },
];

type Props = {
  active: "/" | "/reports" | "/account" | "/team" | "/contact";
  user: userType;
  onCompanySelected: (id: number) => void;
};

export default function Header({ active, user, onCompanySelected }: Props) {
  const [theId, setTheId] = React.useState();

  const handleCompanyChange = (e: any) => {
    setTheId(e.target.value);
    onCompanySelected(parseInt(e.target.value, 10));
  };

  return (
    <Container>
      <DivStyled>
        <Sidebar left>
          <LinkStyled to={user?.userHasOneChannel ? "/overview" : "/"}>
            <img
              src={LogoImage1}
              alt="SuperBam"
              {...toSrcSet(LogoImage2, LogoImage3)}
            />
          </LinkStyled>
        </Sidebar>
        {/*{user && (user.role != 'creator') ? (<GridStyled>*/}
        {/*    <Navigation items={MANAGER_NAVIGATION} active={active} />*/}
        {/*</GridStyled>) : false }*/}
        {/*     (<GridStyled>*/}
        {/*    <Navigation items={NAVIGATION} active={active} />*/}
        {/* </GridStyled>)}*/}
      </DivStyled>
      <Sidebar right>
        <select
          className="select-company"
          onChange={handleCompanyChange}
          value={theId}
        >
          {user && user.companies && user.companies.length > 1 ? (
            user.companies.map((item: any) => (
              <option
                key={item.company_id}
                value={item.company_id}
                selected={item.company_id === user.selected_company}
              >
                {item.company_name}
              </option>
            ))
          ) : (
            <option>
              {user && user.companies.length
                ? user.companies[0].company_name
                : "No company"}
            </option>
          )}
        </select>
        {/*{user && (user.role == 'manager' || user.role == 'admin') ? (<>*/}
        {/*    <Username>*/}
        {/*        Channel:*/}
        {/*    </Username>*/}
        {/*        <SelectDiv><Username><Select onChange={handleChannelChange}>*/}
        {/*            {user && user.channels ? user.channels.map((item: any) => {*/}
        {/*                return <>*/}
        {/*                    {*/}
        {/*                        user.channels.map((data: any) => {*/}
        {/*                            if (item === data.youtubeChannelId) return <option*/}
        {/*                                selected={item == user.selected_channel}*/}
        {/*                                value={item}>{data.channelName}</option>*/}
        {/*                        })}</>*/}
        {/*            }) : false}*/}
        {/*        </Select></Username></SelectDiv></>*/}
        {/*) : false}*/}
      </Sidebar>
      <Sidebar right>
        <Contact active={active === "/contact"} />
        <UserProfile active={active === "/account"} user={user} />
      </Sidebar>
    </Container>
  );
}
