import * as React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  margin: auto;
`;

export const Username = styled.h5`
  font-family: ${(props) => props.theme.fonts.backIssuesBB};
  font-size: 0.9375em;
  font-style: italic;
  font-weight: 700;
  margin: auto 0;
  text-transform: uppercase;
`;

type linkProps = {
  active: boolean;
};
export const LinkStyled = styled(({ active, ...rest }) => (
  <Link {...rest} />
))<linkProps>`
  ${(props) =>
    props.active &&
    css`
      -webkit-text-stroke: 1px ${props.theme.colors.black};
      color: ${props.theme.colors.sunYellow};
      position: relative;
      text-shadow: 1px 2px 0 ${props.theme.colors.cherryRed};

      &::after {
        background: ${props.theme.colors.black};
        bottom: -6px;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    `};
`;
