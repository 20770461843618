import { Card, Button } from "@material-ui/core";
import React from "react";

import { useRef, useState } from "react";
import { ClaimCheck } from "./ClaimCheck";
import { ClaimCheckInstructions } from "./ClaimCheckInstructions";
import { ClaimDetails } from "./ClaimDetails";
import { ClaimSubmit } from "./ClaimSubmit";
import Header from "../../components/Header";
import UserInfo from "../../components/UserInfo";
import { Disclaimer, Section } from "../Overview/styled";
import { userType } from "../../contexts/AuthProvider";

const enum STEPS {
  check = 0,
  detail,
  submit,
}

type Props = {
  user: userType;
  onCompanySelected: (id: number) => void;
};

export const ClaimChecker = ({ user, onCompanySelected }: Props) => {
  const [videoId, setVideoId] = useState("");
  const [step, setStep] = useState(STEPS.check);
  const [claims, setClaims] = useState([]);
  const [message, setMessage] = useState(false);

  const handleResp = (arr, _videoId) => {
    setVideoId(_videoId);
    setClaims(arr);
    setStep(STEPS.detail);
  };

  return (
    <>
      <Header active="/" user={user} onCompanySelected={onCompanySelected} />
      <Section>
        <UserInfo title="Claim Checker" user={user} />
        <div style={{ alignItems: "center" }}>
          <p>
            Welcome to the SuperBam Claim Checker. The Claim Checker
            cross-references the submitted video against active claims in ALL
            your channels. If you have more than one channel, we will search
            that too!
          </p>
          <p>Enter a video URL and check if a claim exists.</p>
        </div>
        {message ? <ClaimCheckInstructions /> : false}

        {step === STEPS.check && (
          <ClaimCheck handleResp={handleResp} newMessage={setMessage} />
        )}
        {step === STEPS.detail && (
          <ClaimDetails
            claims={claims}
            handleBack={() => setStep(STEPS.check)}
            gotoSubmit={() => setStep(STEPS.submit)}
          />
        )}
        {step === STEPS.submit && (
          <ClaimSubmit
            videoId={videoId}
            selected_channel={user?.channels}
            handleBack={() => setStep(STEPS.check)}
          />
        )}
        <Disclaimer>
          <strong>
            Please note that YouTube data is typically delayed by at least 72
            hours. It's possible that a video showing as "unclaimed" is claimed
            due to this time delay.
          </strong>
        </Disclaimer>
      </Section>
    </>
  );
};
