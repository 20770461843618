import * as React from 'react';

import {toSrcSet} from '../../services/helpers';

import {
    ButtonStyled,
    ContainerGrid,
    LoginBox,
    LogoImage,
    GridItem,
    InputLabel,
} from './styled';

import LogoPicture1 from './images/Logo.png';
import LogoPicture2 from './images/Logo@2x.png';
import LogoPicture3 from './images/Logo@3x.png';
import history from '../../@history';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
// import { useHistory } from "react-router";

const data = {
    password: '',
    confirmPassword: '',
    passwordError: '',
    confirmPasswordError: '',
    enableButton: false,
    passwordToken: '',
};

export default function ResetPassword() {
    const context = useContext(AuthContext);
    // const history = useHistory();

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            if (event.target.value.length > 4) {
                data.password = event.target.value
            }
        };

    const handleConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            if (event.target.value.length > 4) {
                data.confirmPassword = event.target.value
            }
            data.enableButton = !!(data.password && data.password === data.confirmPassword);
        };

    const handleResetPassword = () => {
        // console.log(data.password);
        context.updatePassword(data.password);
        // context.user?.resetPassword = false;
        // history.push("/");
        history.push("/");
    };

    return (
        <ContainerGrid>
            <GridItem>
                <LoginBox>
                    <LogoImage src={LogoPicture1} alt="SuperBam" {...toSrcSet(LogoPicture2, LogoPicture3)} />
                        <InputLabel type="password" placeholder="Password" required onChange={handlePasswordChange}/>
                        <InputLabel type="password" placeholder="Confirm Password" required
                                    onChange={handleConfirmPasswordChange}/>
                        <ButtonStyled background="blue" onClick={handleResetPassword}>
                            Update Password
                        </ButtonStyled>

                </LoginBox>
            </GridItem>
        </ContainerGrid>
    );
}
