import * as React from "react";
import { gql } from "@apollo/client";
import { ApolloClient } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { NormalizedCacheObject } from "@apollo/client/cache";
import { ApolloProvider } from "@apollo/client";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import auth0, {
  Auth0DecodedHash,
  Auth0Error,
  Auth0UserProfile,
} from "auth0-js";

import { apolloClientType, getApolloClient } from "../services/apollo";

type acceptType = "acceptedGdpr" | "acceptedPp" | "acceptedTc";

const BASE_URL = process.env.REACT_APP_BASE_URL || "";

type addressFieldsType = {
  city: null | string;
  country: null | string;
  state: null | string;
  street: null | string;
  street2: null | string;
  zipcode: null | string;
};

export class ApiWrapper {
  request(path: string = "", method: string = "POST", parameters: object = {}) {
    let body = JSON.stringify(parameters);

    return fetch(path, {
      method: method,
      body: body,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    });
  }

  requestGet(
    path: string = "",
    method: string = "GET",
    parameters: object = {}
  ) {
    return fetch(path, {
      method: method,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    });
  }
}

type addressType = {
  id: number;
  type: string;
} & addressFieldsType;

type userExtraFields = {
  channels: any;
  companies: any;
  provider: string;
};

type userFields = {
  id: number;
  acceptedGdpr: null | string;
  acceptedPp: null | string;
  acceptedTc: null | string;
  addresses: addressType[];
  createdAt: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  permissions: any;
  role: string;
  creators: any;
  creator_: any;
  selected_company: any;
  selected_channel: string;
  interval: string;
  resetPassword: boolean;
  userHasOneChannel: boolean;
} & userExtraFields;

export type userType = null | userFields;

type profileCallback = (
  error: Auth0Error | null,
  profile: Auth0UserProfile
) => void;

const defaultContext = {
  getProfile: (cb: profileCallback) => {},
  isAuthenticated: () => false,
  login: (email: string, password: string) => {},
  onResetPassword: (password: string, passwordToken: string) => {},
  onAuthResetPassword: (
    password: string,
    newPassword: string,
    confirmPassword: string
  ) => {},
  logout: () => {},
  hasAccepted: () => false,
  handleAccept: () => {},
  onCompanySelected: (id: number) => {},
  getSelectedCompany: () => {},
  setInterval: (value: string) => {},
  onForgotPassword: (email: string) => {},
  selectedUser: (id: number) => {},
  selectedChannel: (id: string) => {},
  getSelectedChannel: () => {},
  requestPasswordReset: () => {},
  updateUser: (
    name: string,
    surname: string,
    id: number,
    address: addressFieldsType
  ) => {},
  updateCompanyBusinessAddress: (
    type: string,
    companyId: number,
    address: addressFieldsType
  ) => {},
  updateCompanyMailingAddress: (
    type: string,
    companyId: number,
    address: addressFieldsType
  ) => {},
  updatePassword: (password: string) => {},
  authenticated: false,
  loaded: false,
  user: null,
  passwordReset: false,
  emailSentMessage: "",
  passwordResetMessage: "",
  loginFailMessage: "",
  userHasOneChannel: false,
};

type State = {
  authenticated: boolean;
  passwordReset: boolean;
  passwordResetMessage: string;
  emailSentMessage: string;
  loginFailMessage: string;
  loaded: boolean;
  user: userType;
};

export type contextType = {
  getProfile: (cb: profileCallback) => void;
  isAuthenticated: () => boolean;
  login: (email: string, password: string) => void;
  onCompanySelected: (id: number) => void;
  getSelectedCompany: () => any;
  onForgotPassword: (email: string) => void;
  onResetPassword: (password: string, passwordToken: string) => void;
  onAuthResetPassword: (
    password: string,
    newPassword: string,
    confirmPassword: string
  ) => void;
  selectedUser: (id: number) => void;
  selectedChannel: (id: string) => void;
  getSelectedChannel: () => any;
  setInterval: (value: string) => void;
  logout: () => void;
  hasAccepted: () => boolean;
  handleAccept: (gdpr: string, terms: string, privacy: string) => void;
  requestPasswordReset: () => void;
  updateUser: (
    name: string,
    surname: string,
    addressId: number,
    address: addressFieldsType
  ) => void;
  updatePassword: (password: string) => void;

  updateCompanyBusinessAddress: (
    type: string,
    companyId: number,
    address: addressFieldsType
  ) => void;
  updateCompanyMailingAddress: (
    type: string,
    companyId: number,
    address: addressFieldsType
  ) => void;
} & State;

export const AuthContext = React.createContext<contextType>(defaultContext);

const GET_USER = gql`
  query getUser($id: Int!) {
    user(id: $id) @rest(type: "User", path: "/users/{args.id}") {
      id
      acceptedGdpr
      acceptedPp
      acceptedTc
      addresses @type(name: "[Address]") {
        id
        city
        country
        state
        street
        street2
        type
        zipcode
      }
      createdAt
      email
      firstName
      lastName
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $date: String!) {
    updateUser(
      id: $id
      input: { acceptedGdpr: $date, acceptedPp: $date, acceptedTc: $date }
    ) @rest(type: "User", path: "/users/{args.id}", method: "POST") {
      acceptedGdpr
      acceptedPp
      acceptedTc
    }
  }
`;

const UPDATE_NAME_USER = gql`
  mutation updateUserName(
    $id: Int!
    $name: String!
    $surname: String!
  ) {
    updateUserName(
      id: $id
      input: { firstName: $name, lastName: $surname }
    ) @rest(type: "User", path: "/users/{args.id}", method: "POST") {
      firstName
      lastName
    }
  }
`;

const UPDATE_PASSWORD_USER = gql`
  mutation updatePassword($id: Int!, $password: String!) {
    updatePassword(id: $id, input: { password: $password })
      @rest(type: "User", path: "/users/{args.id}", method: "POST") {
      password
    }
  }
`;

const LOGIN = gql`
  mutation login($email: String, $password: String!) {
    login(email: $email, password: $password)
      @rest(type: "User", path: "/v1/login", method: "POST") {
      email
      password
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation updateAddress(
    $userId: Int!
    $city: String!
    $country: String!
    $state: String!
    $street: String!
    $street2: String!
    $zipcode: String!
  ) {
    updateAddress(
      userId: $userId
      input: {
        city: $city
        country: $country
        state: $state
        street: $street
        street2: $street2
        zipcode: $zipcode
      }
    )
      @rest(
        type: "Address"
        path: "/users/{args.userId}/address"
        method: "POST"
      ) {
      city
      country
      state
      street
      street2
      type
      zipcode
    }
  }
`;

const UPDATE_COMPANY_ADDRESS = gql`
  mutation updateAddress(
    $companyId: Int!
    $city: String!
    $country: String!
    $state: String!
    $street: String!
    $street2: String!
    $zipcode: String!
    $type: String!
  ) {
    updateCompanyAddresses(
      companyId: $companyId
      type: $type
      input: {
        city: $city
        country: $country
        state: $state
        street: $street
        street2: $street2
        zipcode: $zipcode
      }
    )
      @rest(
        type: "Address"
        path: "/companies/{args.companyId}/{args.type}"
        method: "POST"
      ) {
      city
      country
      state
      street
      street2
      zipcode
    }
  }
`;

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($clientId: String!, $email: String!) {
    requestPasswordReset(
      input: {
        clientId: $clientId
        email: $email
        connection: "Username-Password-Authentication"
      }
    )
      @rest(
        type: "Password"
        path: "/dbconnections/change_password"
        method: "POST"
        endpoint: "auth0"
      )
  }
`;

type Props = RouteComponentProps<any> &
  WithApolloClient<{
    children: React.ReactNode;
  }>;

class AuthProvider extends React.Component<Props, State> {
  apolloClient: apolloClientType = null;

  auth0 = new auth0.WebAuth({
    audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
    clientID: process.env.REACT_APP_AUTH_CLIENT_ID || "",
    domain: process.env.REACT_APP_AUTH_DOMAIN || "",
    redirectUri: process.env.REACT_APP_AUTH_CALLBACK_URL || "",
    responseType: "token id_token",
    scope: "openid profile email read:current_user",
  });

  constructor(props: Props) {
    super(props);
    const authenticated =
      new Date().getTime() <
      JSON.parse(localStorage.getItem("expires_at") || "{}");
    this.state = {
      authenticated,
      loaded: false,
      user: null,
      passwordReset: false,
      emailSentMessage: "",
      loginFailMessage: "",
      passwordResetMessage: "",
    };
    if (!authenticated) {
      this.setState({ authenticated: false });
    }
  }

  componentDidMount() {
    if (/access_token|id_token|error/.test(window.location.hash)) {
      this.handleAuthentication();
    }
    if (
      /forgot/.test(window.location.pathname) ||
      /reset_password/.test(window.location.pathname)
    ) {
      this.setState({ passwordReset: true });
    }
    if (this.state.authenticated) {
      this.loadProfile();
    }
  }

  getAccessToken = (): string => localStorage.getItem("access_token") || "";

  getToken = (): string => localStorage.getItem("id_token") || "";

  getProfile = (cb: profileCallback): void => {
    this.auth0.client.userInfo(this.getAccessToken(), (err, profile) => {
      cb(err, profile);
    });
  };

  requestPasswordReset = () => {
    const { user } = this.state;
    if (!user) return;
    this.fetchPasswordRequest(user);
  };

  async fetchPasswordRequest(user: userFields) {
    try {
      await this.apolloClient?.mutate({
        mutation: REQUEST_PASSWORD_RESET,
        variables: {
          clientId: process.env.REACT_APP_AUTH_CLIENT_ID || "",
          email: user.email,
        },
      });
    } catch (e) {}
  }

  async fetchUser(userId: number, extraFields: userExtraFields) {
    if (!this.apolloClient) return;
    const {
      data: { user },
    } = await this.apolloClient.query({
      query: GET_USER,
      variables: {
        id: userId,
      },
    });
    this.setState({
      loaded: true,
      user: {
        ...user,
        ...extraFields,
        fullName: `${user.firstName} ${user.lastName}`,
      },
    });
  }
  onCompanySelected = (id: number) => {
    const { user } = this.state;
    if (user) {
      const company = user.companies.find(
        (item: any) => item.company_id === id
      );
      this.setState({
        user: {
          ...user,
          selected_company: id,
          channels: company.channels,
        },
      });
    }
  };

  getSelectedCompany = () => {
    const { user } = this.state;
    if (user) {
      return user.companies.find(
        (item: any) => item.company_id === user.selected_company
      );
    }
  };

  setInterval = (value: string) => {
    console.log(value);
    const { user } = this.state;
    if (!user) return;
    this.setState({
      user: {
        ...user,
        interval: value,
      },
    });
  };
  selectedUser = (id: number) => {
    const { user } = this.state;
    if (user) {
      const creator = user.creators.filter((item: any) => item.id === id)[0];
      this.setState({
        user: {
          ...user,
          creator_: creator,
        },
      });
    }
  };

  selectedChannel = (id: string) => {
    const { user } = this.state;
    if (user) {
      this.setState({
        user: {
          ...user,
          selected_channel: id,
        },
      });
    }
  };

  getSelectedChannel = () => {
    const { user } = this.state;
    if (user) {
      return user.channels.find(
        (item: any) => item.channel_id === user.selected_channel
      );
    }
  };

  async forgotPassword(email: string) {
    const apiWrapper = new ApiWrapper();
    const response = await apiWrapper.request(
      `${BASE_URL}/request_password_reset`,
      "POST",
      { email: email }
    );
    const data = await response.json();
    this.setState({ emailSentMessage: data.message, passwordReset: false });
  }

  onForgotPassword = (email: string) => {
    this.forgotPassword(email);
  };

  async updateUserAccept(gdpr: string, terms: string, privacy: string) {
    const { user } = this.state;

    const now = new Date();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const date = `${now.getFullYear()}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;

    const apiWrapper = new ApiWrapper();
    const response = await apiWrapper.request(
      `${BASE_URL}/users/${user ? user.id : ""}`,
      "POST",
      {
        accepted_gdpr: date,
        accepted_pp: date,
        accepted_tc: date,
      }
    );
    const data = await response.json();
    this.setState(state => {
      if (state && state.user) {
        state.user.acceptedGdpr = data.accepted_gdpr;
        state.user.acceptedPp = data.accepted_pp;
        state.user.acceptedTc = data.accepted_tc;
      }
      return state;
    });
  }

  async loadProfile() {
    const apiWrapper = new ApiWrapper();
    const response = await apiWrapper.requestGet(
      `${BASE_URL}/user_details`,
      "GET",
      {}
    );
    if (response.status === 200) {
      const data = await response.json();
      this.apolloClient = getApolloClient("Bearer " + this.getAccessToken());
      // const creator = singleItem.creators ? singleItem.creators[0] : null;
      const companies = data.user_metadata.companies;
      const channels = companies.length > 0 ? companies[0].channels : [];
      this.setState({
        loaded: true,
        user: {
          id: data.user_id,
          firstName: data.first_name,
          lastName: data.last_name,
          fullName: (data.first_name || "") + " " + (data.last_name || ""),
          createdAt: data.created_at,
          email: data.email,
          role: data.role,
          acceptedTc: data.accepted_tc,
          acceptedGdpr: data.accepted_gdpr,
          acceptedPp: data.accepted_pp,
          channels: channels,
          companies: companies,
          selected_company:
            companies.length > 0 ? companies[0].company_id : null,
          selected_channel: channels.length > 0 ? channels[0].channel_id : null,
          creator_: null,
          permissions: data.user_metadata.permissions,
          userHasOneChannel: channels.length < 2,
          addresses: [],
          creators: null,
          interval: "",
          resetPassword: false,
          provider: "",
        },
      });
    } else {
      this.logout();
    }
    const { history } = this.props;
    if (this.state.user) {
      if (this.state.user.userHasOneChannel) {
        history && history.replace("/overview");
      } else {
        history && history.replace("/");
      }
    }
  }

  isAuthenticated = (): boolean => this.state.authenticated;

  setSession = (authResult: any): void => {
    localStorage.setItem("access_token", authResult.access_token || "");
    localStorage.setItem(
      "expires_at",
      JSON.stringify((authResult.expires_at || 1) * 1000 + new Date().getTime())
    );
    this.setState({ authenticated: true });
    this.loadProfile();
  };

  handleAuthentication(): void {
    const { history } = this.props;
    if (this.state.authenticated) this.getAccessToken();
    history && history.replace("/");
  }

  logout = (): void => {
    const { history } = this.props;
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
    history && history.replace("/");
    window.location.reload();
  };

  async jwtLogin(email: string, password: string) {
    const apiWrapper = new ApiWrapper();
    const response = await apiWrapper.request(`${BASE_URL}/login`, "POST", {
      email,
      password,
    });
    const data = await response.json();
    if (response.status === 200) {
      this.setState({ loginFailMessage: "" });
      this.setSession(data);
    } else {
      this.setState({ loginFailMessage: data.message });
      console.log(this.state.loginFailMessage);
    }
  }

  async resetPassword(password: string, passwordToken: string) {
    const apiWrapper = new ApiWrapper();
    const response = await apiWrapper.request(
      `${BASE_URL}/reset_password`,
      "POST",
      {
        password: password,
        token: passwordToken,
      }
    );
    const data = await response.json();
    this.setState({ emailSentMessage: data.message, passwordReset: false });
  }

  async authResetPassword(
    password: string,
    newPassword: string,
    confirmPassword: string
  ) {
    const { user } = this.state;
    const apiWrapper = new ApiWrapper();
    const response = await apiWrapper.request(
      `${BASE_URL}/change_password`,
      "POST",
      {
        password: password,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }
    );
    if (response.status === 204) {
      this.setState({
        passwordResetMessage: "Password has been reset.",
        passwordReset: false,
      });
    } else {
      const data = await response.json();
      this.setState({
        passwordResetMessage: data.message,
        passwordReset: false,
      });
    }
  }

  login = (email: string, password: string): void => {
    this.jwtLogin(email, password);
  };

  onResetPassword = (password: string, passwordToken: string): void => {
    this.resetPassword(password, passwordToken);
  };

  onAuthResetPassword = (
    password: string,
    newPassword: string,
    confirmPassword: string
  ): void => {
    this.authResetPassword(password, newPassword, confirmPassword);
  };

  handleAccept = (gdpr: string, terms: string, privacy: string): void => {
    this.updateUserAccept(gdpr, terms, privacy);
  };

  hasAccepted = (): boolean => {
    const { user } = this.state;
    const fields = ["acceptedGdpr", "acceptedPp", "acceptedTc"];

    for (let field of fields) {
      // TODO - possibly check acceptance dates
      console.log(user ? user[field] : false);
      if (!user || !user[field]) return false;
    }

    return true;

    // return fields.reduce((accum, field) => {
    //     // TODO - possibly check acceptance dates
    //     if (!user || !user[field as acceptType]) accum = false;
    //     return accum;
    // }, true);
  };

  async updateUserName(
    id: number,
    client: ApolloClient<NormalizedCacheObject>,
    name: string,
    surname: string
  ): Promise<userFields> {
    const { data } = await client.mutate({
      mutation: UPDATE_NAME_USER,
      variables: { id, name, surname },
    });

    return data.updateUserName;
  }

  async updateAddress(
    userId: number,
    client: ApolloClient<NormalizedCacheObject>,
    addressId: number,
    address: addressFieldsType,
    addresses: addressType[]
  ): Promise<addressType[]> {
    const { data } = await client.mutate({
      mutation: UPDATE_ADDRESS,
      variables: { ...address, userId, addressId },
    });

    return addresses.map((address) =>
      address.id === addressId ? data.updateAddress : address
    );
  }

  async updateCompanyAddresses(
    companyId: number,
    client: ApolloClient<NormalizedCacheObject>,
    addressId: number,
    type: string,
    address: addressFieldsType,
    addresses: addressType[]
  ): Promise<addressType[]> {
    const { data } = await client.mutate({
      mutation: UPDATE_COMPANY_ADDRESS,
      variables: { ...address, type, companyId },
    });

    return addresses.map((address) =>
      address.id === addressId ? data.updateCompanyAddresses : address
    );
  }

  async doUpdateUser(
    name: string,
    surname: string,
    id: number,
    address: addressFieldsType
  ) {
    const { user } = this.state;

    if (!user || !this.apolloClient) return;

    const newUser = await this.updateUserName(
      user.id,
      this.apolloClient,
      name,
      surname
    );

    this.setState({
      user: {
        ...user,
        ...newUser,
        fullName: (newUser.firstName || "") + " " + (newUser.lastName || ""),
        // addresses,
      },
    });
  }

  async doUpdateCompanyAddress(
    type: string,
    id: number,
    address: addressFieldsType
  ) {
    const company = this.getSelectedCompany();
    if (!company || !this.apolloClient) return;
    const addresses = await this.updateCompanyAddresses(
      company.company_id,
      this.apolloClient,
      id,
      type,
      address,
      company.addresses ? company.addresses : []
    );
    // this.setState({
    //     user: {
    //         ...user,
    //         addresses,
    //     },
    // });
  }

  async doUpdatePassword(password: string) {
    const { user } = this.state;
    if (!user || !this.apolloClient) return;

    const { data } = await this.apolloClient.mutate({
      mutation: UPDATE_PASSWORD_USER,
      variables: { id: user.id, password },
    });
  }

  updatePassword = (password: string) => {
    this.doUpdatePassword(password);
  };

  updateUser = (
    name: string,
    surname: string,
    id: number,
    address: addressFieldsType
  ) => {
    this.doUpdateUser(name, surname, id, address);
  };
  updateCompanyBusinessAddress = (
    type: string,
    companyId: number,
    address: addressFieldsType
  ) => {
    this.doUpdateCompanyAddress(type, companyId, address);
  };
  updateCompanyMailingAddress = (
    type: string,
    companyId: number,
    address: addressFieldsType
  ) => {
    this.doUpdateCompanyAddress(type, companyId, address);
  };

  render() {
    const { children } = this.props;

    const {
      getProfile,
      handleAccept,
      hasAccepted,
      isAuthenticated,
      login,
      logout,
      requestPasswordReset,
      updateUser,
      updateCompanyBusinessAddress,
      updateCompanyMailingAddress,
      onCompanySelected,
      getSelectedCompany,
      onForgotPassword,
      onResetPassword,
      onAuthResetPassword,
      selectedUser,
      selectedChannel,
      getSelectedChannel,
      setInterval,
      updatePassword,
    } = this;
    const value = {
      getProfile,
      handleAccept,
      hasAccepted,
      isAuthenticated,
      login,
      logout,
      requestPasswordReset,
      updateUser,
      updateCompanyBusinessAddress,
      updateCompanyMailingAddress,
      onCompanySelected,
      getSelectedCompany,
      onForgotPassword,
      onResetPassword,
      onAuthResetPassword,
      selectedUser,
      selectedChannel,
      getSelectedChannel,
      setInterval,
      updatePassword,
      ...this.state,
    };

    const content = this.apolloClient ? (
      <ApolloProvider client={this.apolloClient}>{children}</ApolloProvider>
    ) : (
      children
    );

    return <AuthContext.Provider value={value}>{content}</AuthContext.Provider>;
  }
}

export default withRouter(AuthProvider);
