import * as React from 'react';

import {toSrcSet} from '../../services/helpers';

import {
    ButtonStyled,
    ContainerGrid,
    LoginBox,
    LogoImage,
    GridItem,
    InputLabel,
} from './styled';

import LogoPicture1 from './images/Logo.png';
import LogoPicture2 from './images/Logo@2x.png';
import LogoPicture3 from './images/Logo@3x.png';
import {HomeButton} from "../Account/styled";
import history from '../../@history';
import {useEffect} from "react";
import {InfoText} from "../Login/styled";

type Props = {
    onAuthResetPassword: (password: string, newPassword: string, confirmPassword: string) => void;
    passwordResetMessage: string;
};

const data = {
    password: '',
    newPassword: '',
    confirmPassword: '',
    passwordError: '',
    confirmPasswordError: '',
    enableButton: false,
};

export default function NewResetPassword({onAuthResetPassword, passwordResetMessage}: Props) {

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            if (event.target.value.length > 4) {
                data.password = event.target.value
            }
        };

    const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            if (event.target.value.length > 4) {
                data.newPassword = event.target.value
            }
        };

    const handleConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            if (event.target.value.length > 4) {
                data.confirmPassword = event.target.value
            }
            data.enableButton = !!(data.newPassword && data.newPassword === data.confirmPassword);
        };

    const handleResetPassword = () => {
        if (data.newPassword === data.confirmPassword)
            onAuthResetPassword(data.password, data.newPassword, data.confirmPassword)
        else {
            data.passwordError = 'Password does not match please re-enter the New or Confirm Password.'
            data.enableButton = false;
        }
    };

    return (
        <ContainerGrid>
            <GridItem>
                <LoginBox>
                    <LogoImage src={LogoPicture1} alt="SuperBam" {...toSrcSet(LogoPicture2, LogoPicture3)} />
                    {passwordResetMessage.length ? <><p>{passwordResetMessage}</p>
                        <a href={"/account"}>Go to Personal Information</a>
                    </> : <>
                        {data.passwordError ? <InfoText>{data.passwordError}</InfoText> : false}
                        <InputLabel type="password" placeholder="Old Password" required onChange={handlePasswordChange}/>
                        <InputLabel type="password" placeholder="New Password" required onChange={handleNewPasswordChange}/>
                        <InputLabel type="password" placeholder="Confirm Password" required
                                    onChange={handleConfirmPasswordChange}/>
                        <ButtonStyled background="blue" onClick={handleResetPassword}>
                            Update Password
                        </ButtonStyled></>}

                </LoginBox>
            </GridItem>
        </ContainerGrid>
    );
}
