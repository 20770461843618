const theme = {
  colors: {
    black: '#000',
    black60: 'rgba(0, 0, 0, 0.6)',
    buff: '#faf5a4',
    cherryRed: '#ec2027',
    denimBlue: '#365899',
    dodgerBlue: '#4285f4',
    fadedRed: '#dc3b5d',
    lightblue: '#75c5f5',
    seafoamBlue: '#66c6d8',
    shamrockGreen: '#00c853',
    shamrockGreen60: 'rgba(0, 200, 83, 0.6)',
    sunYellow: '#faed23',
    white: '#fff',
    whiteTwo: '#fafafa',
  },
  fonts: {
    backIssuesBB: 'BackIssuesBB',
    roboto: "'Roboto', sans-serif",
  },
  container: '62.5rem',
};

export type themeType = typeof theme;

export default theme;
