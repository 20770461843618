import styled, { css } from 'styled-components';

export const Title = styled.h3`
  align-items: center;
  background: ${props => props.theme.colors.buff};
  border-bottom: solid 1px ${props => props.theme.colors.black};
  display: flex;
  font-family: ${props => props.theme.fonts.backIssuesBB};
  font-size: 0.9375em;
  font-weight: 400;
  height: 2.5rem;
  margin: 0;
  padding: 0 1.25rem;
  text-transform: uppercase;
`;

export const Content = styled.div`
  padding: 20px;
  position: relative;
`;

type containerProps = {
  shortTitle: boolean;
};
export const Container = styled.div<containerProps>`
  background: ${props => props.theme.colors.whiteTwo};
  border: solid 1px ${props => props.theme.colors.black};
  box-shadow: 1px 2px 0 0 ${props => props.theme.colors.black};
  width: 100%;
  margin: 0px 10px 10px 0px;

  ${props =>
    props.shortTitle &&
    css`
      position: relative;

      ${Title} {
        border-right: solid 1px ${props => props.theme.colors.black};
        left: 0;
        position: absolute;
        top: 0;
      }

      ${Content} {
        padding-top: 60px;
      }
    `};
`;
