import React, { useState } from "react";

const tdStyle = {
  borderTop: "1px solid black",
  padding: "10px 20px",
};

const anchorStyle = {
  textDecoration: "underline",
  fontStyle: "italic",
};

export const ClaimDetailsRow = ({ index, claim }) => {
  const [expanded, setExpanded] = useState(false);
  const onClickToggleExpand = () => {
    setExpanded((v) => !v);
  };

  return (
    <tr>
      <td style={tdStyle}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "100px auto",
            rowGap: 4,
          }}
        >
          <label>date</label>
          <span>{claim.targetVideo.date}</span>
          <label>video id</label>
          <span>
            {claim.targetVideo.videoId ? (
              <a
                href={
                  "https://www.youtube.com/channel/" + claim.targetVideo.videoId
                }
                target={"_blank"}
                style={anchorStyle}
                rel="noreferrer"
              >
                youtube.com/watch?v={claim.targetVideo.videoId}
              </a>
            ) : (
              "No video id"
            )}
          </span>
        </div>
      </td>
      <td style={tdStyle}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "144px auto",
            rowGap: 4,
          }}
        >
          <label>asset id</label>
          <span>{claim.originalVideo.assetId}</span>
          {/*<label>season number</label>*/}
          {/*<span>{claim.originalVideo.season}</span>*/}
          <label>episode title</label>
          <span>{claim.originalVideo.episodeTitle}</span>
          {/*<label>custom id</label>*/}
          {/*<span>{claim.originalVideo.customId}</span>*/}
          {/*<label>channel</label>*/}
          {/*<div>*/}
          {/*    <span>{claim.originalVideo.channel.channelName}</span>*/}
          {/*    <span style={{borderRadius: '50%', cursor: 'pointer', border: '1px solid gray', padding: '0 10px', userSelect:'none', marginLeft: 10}} onClick={onClickToggleExpand}>&#8230;</span>*/}
          {/*</div>*/}

          {expanded ? (
            <div
              style={{
                width: "100%",
                overflowX: "auto",
                gridColumn: "1/-1",
                borderRadius: 10,
                border: "1px solid grey",
              }}
            >
              <pre>
                {JSON.stringify(claim.originalVideo.channel, undefined, 2)}
              </pre>
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
    </tr>
  );
};
