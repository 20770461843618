import styled, { keyframes } from 'styled-components';

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

export const Loader = styled.div`
  height: 64px;
  margin: auto;
  width: 64px;

  &::after {
    animation: ${loaderAnimation} 1.2s linear infinite;
    border-color: ${props => props.theme.colors.black} transparent;
    border-style: solid;
    border-width: 5px;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 46px;
    margin: 1px;
    width: 46px;
  }
`;
