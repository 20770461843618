import * as React from 'react';

import {toSrcSet} from '../../services/helpers';

import {
    ButtonStyled,
    ContainerGrid,
    LoginBox,
    LogoImage,
    GridItem,
    InputLabel,
} from './styled';

import LogoPicture1 from './images/Logo.png';
import LogoPicture2 from './images/Logo@2x.png';
import LogoPicture3 from './images/Logo@3x.png';
import {HomeButton} from "../Account/styled";

type Props = {
    onForgotPassword: (email: string) => void;
    emailSentMessage: string;
};

const data = {
    email: '',
};

export default function ForgotPassword({onForgotPassword, emailSentMessage}: Props) {

    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            data.email = event.target.value
        };

    const handleForgotPassword = () => {
        onForgotPassword(data.email)
    };

    return (
        <ContainerGrid>
            <GridItem>
                <LoginBox>
                    <LogoImage src={LogoPicture1} alt="SuperBam" {...toSrcSet(LogoPicture2, LogoPicture3)} />
                    {emailSentMessage.length ? <><p>{emailSentMessage}</p>
                        <a href="/">Go to Login</a>
                    </>: <>
                        <InputLabel type="email" placeholder="Email" required onChange={handleEmailChange}/>

                        <ButtonStyled background="blue" onClick={handleForgotPassword}>
                            Send Email
                        </ButtonStyled></>}
                </LoginBox>
            </GridItem>
        </ContainerGrid>
    );
}
