import styled from 'styled-components';
import ButtonTransparent from "../ButtonTransparent";

export const Username = styled.h5`
  font-family: ${props => props.theme.fonts.backIssuesBB};
  font-size: 1.875em;
  font-style: italic;
  font-weight: bold;
  line-height: 1em;
  margin: 0;
  text-transform: uppercase;
`;

export const SinceDate = styled.p`
  color: ${props => props.theme.colors.black60};
  font-family: ${props => props.theme.fonts.backIssuesBB};
  font-size: 0.6875em;
  font-style: italic;
  line-height: 1.82;
  margin: 0;
  text-transform: uppercase;
`;

export const Title = styled.h1`
  -webkit-text-stroke: 1px ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.sunYellow};
  font-family: ${props => props.theme.fonts.backIssuesBB};
  font-size: 0.9375em;
  font-style: italic;
  font-weight: 700;
  line-height: 2em;
  margin: 0 20px 20px 20px;
  text-shadow: 1px 2px 0 ${props => props.theme.colors.cherryRed};
  text-transform: uppercase;

  ${props => props.theme.mui.breakpoints.up('sm')} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const TitleLine = styled.span`
  position: relative;
  margin: 0px 30px 0px 0px;
  &::after {
    background: ${props => props.theme.colors.black};
    bottom: -6px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  margin: 0 1rem 10px 1rem;

  ${props => props.theme.mui.breakpoints.up('sm')} {
    margin-left: 0;
    margin-right: 0;
  }
`;

// TODO - hover state
export const LogoutButton = styled.button.attrs({ type: 'button' })`
  background: ${props => props.theme.colors.whiteTwo};
  border: solid 1px ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.backIssuesBB};
  font-size: 0.6875em;
  height: 25px;
  margin: 5px 0 0 20px;
  padding: 0 10px;
`;

export const ReportsButton = styled(ButtonTransparent)`
  margin: 0px 30px 0px 0px;
`;

export const ResetPasswordButton = styled(ButtonTransparent)`
  background: ${props => props.theme.colors.whiteTwo};
  border: solid 1px ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.backIssuesBB};
  font-size: 0.6875em;
  height: 25px;
  margin: 5px 0 0 20px;
  padding: 5px 10px 5px 10px;
`;
