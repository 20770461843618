import { RestLink } from 'apollo-link-rest';
import { ApolloClient } from '@apollo/client';
import { camelize, decamelize } from 'humps';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';

export type apolloClientType = null | ApolloClient<NormalizedCacheObject>;

export function getApolloClient(Authorization: string = '') {
  const restLinkOptions: RestLink.Options = {
    endpoints: {
      auth0: `https://${process.env.REACT_APP_AUTH_DOMAIN}`,
    },
    fieldNameNormalizer: key => camelize(key),
    fieldNameDenormalizer: key => decamelize(key),
    uri: process.env.REACT_APP_BASE_URL,
  };

  if (Authorization) {
    restLinkOptions.headers = new Headers({ Authorization });
  }

  const link = new RestLink(restLinkOptions);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}
