import * as React from 'react';

import { toClassNameObj } from '../../services/helpers';

type iconProps = {
  className?: string;
};

export function GoogleIcon({ className = '' }: iconProps) {
  const id = Math.random()
    .toString(36)
    .substring(7);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...toClassNameObj(className)}
    >
      <defs>
        <path
          id={`a${id}`}
          d="M19.767 8.182h-9.534v3.863h5.488c-.512 2.455-2.651 3.864-5.488 3.864-3.35 0-6.047-2.636-6.047-5.909 0-3.273 2.698-5.91 6.047-5.91A6 6 0 0 1 14 5.41l2.977-2.91C15.163.955 12.837 0 10.233 0 4.558 0 0 4.455 0 10s4.558 10 10.233 10C15.349 20 20 16.364 20 10c0-.59-.093-1.227-.233-1.818z"
        />
        <path
          id={`c${id}`}
          d="M19.767 8.182h-9.534v3.863h5.488c-.512 2.455-2.651 3.864-5.488 3.864-3.35 0-6.047-2.636-6.047-5.909 0-3.273 2.698-5.91 6.047-5.91A6 6 0 0 1 14 5.41l2.977-2.91C15.163.955 12.837 0 10.233 0 4.558 0 0 4.455 0 10s4.558 10 10.233 10C15.349 20 20 16.364 20 10c0-.59-.093-1.227-.233-1.818z"
        />
        <path
          id={`e${id}`}
          d="M19.767 8.182h-9.534v3.863h5.488c-.512 2.455-2.651 3.864-5.488 3.864-3.35 0-6.047-2.636-6.047-5.909 0-3.273 2.698-5.91 6.047-5.91A6 6 0 0 1 14 5.41l2.977-2.91C15.163.955 12.837 0 10.233 0 4.558 0 0 4.455 0 10s4.558 10 10.233 10C15.349 20 20 16.364 20 10c0-.59-.093-1.227-.233-1.818z"
        />
        <path
          id={`g${id}`}
          d="M19.767 8.182h-9.534v3.863h5.488c-.512 2.455-2.651 3.864-5.488 3.864-3.35 0-6.047-2.636-6.047-5.909 0-3.273 2.698-5.91 6.047-5.91A6 6 0 0 1 14 5.41l2.977-2.91C15.163.955 12.837 0 10.233 0 4.558 0 0 4.455 0 10s4.558 10 10.233 10C15.349 20 20 16.364 20 10c0-.59-.093-1.227-.233-1.818z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id={`b${id}`} fill="#fff">
            <use xlinkHref={`#a${id}`} />
          </mask>
          <path
            fill="#FBBC05"
            fillRule="nonzero"
            d="M-.93 15.91V4.09L6.977 10z"
            mask={`url(#b${id})`}
          />
        </g>
        <g>
          <mask id={`d${id}`} fill="#fff">
            <use xlinkHref={`#c${id}`} />
          </mask>
          <path
            fill="#EA4335"
            fillRule="nonzero"
            d="M-.93 4.09L6.977 10l3.256-2.773 11.162-1.772V-.91H-.93z"
            mask={`url(#d${id})`}
          />
        </g>
        <g>
          <mask id={`f${id}`} fill="#fff">
            <use xlinkHref={`#e${id}`} />
          </mask>
          <path
            fill="#34A853"
            fillRule="nonzero"
            d="M-.93 15.91L13.023 5.454l3.675.454 4.697-6.818v21.818H-.93z"
            mask={`url(#f${id})`}
          />
        </g>
        <g>
          <mask id={`h${id}`} fill="#fff">
            <use xlinkHref={`#g${id}`} />
          </mask>
          <path
            fill="#4285F4"
            fillRule="nonzero"
            d="M21.395 20.91L6.977 10l-1.86-1.364 16.278-4.545z"
            mask={`url(#h${id})`}
          />
        </g>
      </g>
    </svg>
  );
}
