import * as React from 'react';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

export const Container = styled.header`
  background: ${props => props.theme.colors.whiteTwo};
  border-bottom: solid 2px ${props => props.theme.colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  left: 0;
  position: fixed;
  top: 0;
  padding: 10px 30px;
  width: 100%;
  z-index: 1;
`;

export const GridStyled = styled(Grid as React.FunctionComponent).attrs({ container: true })`
  margin: 0 1rem;

  ${props => props.theme.mui.breakpoints.up('md')} {
    margin: 0 auto;
    max-width: ${props => props.theme.container};
  }
`;

type sidebarProps = {
  left?: boolean;
  right?: boolean;
};
export const Sidebar = styled.div<sidebarProps>`
  display: none;
  height: 100%;
  position: relative;
  top: 0;

  ${props =>
    props.left &&
    css`
      left: 30px;
    `};

  ${props =>
    props.right &&
    css`
      right: 30px;

      ${props => props.theme.mui.breakpoints.up('sm')} {
        display: flex;
      }
    `};

  ${props => props.theme.mui.breakpoints.up('md')} {
    display: flex;
  }
`;

export const LinkStyled = styled(Link)`
  display: block;
  margin: auto;
  width; 130px;
  margin-right: 50px;
`;

export const DivStyled = styled.div<sidebarProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

// export const SelectCompany = styled.select`
//     border: none;
//     background: transparent;
//     font-family: BackIssuesBB;
//     font-size: 0.9375em;
//     font-style: italic;
//     font-weight: 700;
//     margin: auto 0;
//     text-transform: uppercase;
// `;